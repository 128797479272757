@import '../../../styles/shared';

.success-image {
	margin: auto;
}

.input-file {
	display: none;
}
.image-position {
	cursor: pointer;
}

.reverse-order {
	@include media-breakpoint-down('sm') {
		flex-direction: column-reverse;
	}
}

.error-document {
	background-color: color(bg-error-light);
}

.select-expense-header {
	@include flexbox;
	@include flex-direction(column);
	gap: 6px;
}

.radio-button {
	margin-bottom: 4px;
}

.activity-container {
	@include flexbox;
	@include justify-content(center);
	@include align-items(center);
	border: 1px solid color(font-body-disabled);
	border-radius: 18px;
	min-height: 40vh;
	margin-bottom: 30px;
}
.no-activity-text {
	@include text-h4-325;
	color: color(font-body-disabled);
	@include justify-content('flex-start');
}
