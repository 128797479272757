@import '../../styles/shared';

$main-margin: 10px;

@mixin media-max-width() {
	@media (max-width: 550px) {
		@content;
	}
}

.container {
	@include flexbox;
	@include justify-content(center);
	min-height: 100vh;
	overflow-y: auto;
	background-color: color(blue-900);
}

.header {
	@include flexbox;
	@include justify-content(center);
	@include align-items(center);
	@include flex-direction(column);
	gap: 10px;
	background-color: color(blue-900);
	padding: 80px 12px;
}
