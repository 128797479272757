@import '../../../styles/shared';

@mixin btn {
	@include flexbox;
	@include justify-content(center);
	@include align-items(center);
	cursor: pointer;
	/* Sapphire/Default */
	border-radius: 24px !important;
	border-style: solid;
	border-width: 1px;
}

.wrap-in-line {
	white-space: nowrap;
}
// Primary button related styles
.btn-primary {
	@include btn;
	background-color: color(blue-900);
	color: color(gray-0);
	border-color: color(gray-0);
	&:hover {
		background-color: color(blue-800);
		border-color: color(gray-0);
	}
	&:focus {
		background-color: color(blue-1000);
		border-color: color(gray-0);
		outline: 2px solid color(blue-outline);
		outline-offset: 4px;
	}
}
.btn-primary.disabled {
	background-color: color(gray-300);
	border-color: color(gray-300);
	pointer-events: none;
}
.btn-primary.error {
	background-color: color(danger-500);
	border-color: color(danger-500);
}
// Secondary button related styles

.btn-secondary {
	@include btn;
	color: color(primary-800);
	background-color: color(gray-0);
	border-color: color(primary-800);

	&:hover {
		border-width: 1px;
		color: color(primary-800);
		border-color: color(primary-800);
		background-color: color(primary-50);
		svg > path {
			fill: color(primary-800);
		}
	}
	&:focus {
		border-width: 1px;
		color: color(blue-900);
		background-color: color(primary-100);
		border-color: color(blue-900);
		svg > path {
			fill: color(blue-900);
		}
		outline: 2px solid color(blue-outline);
		outline-offset: 4px;
	}
}

.btn-secondary.disabled {
	background-color: color(gray-0);
	color: color(bg-cta-disabled);
	border-color: color(bg-cta-disabled);
	pointer-events: none;
}

.btn-secondary.error {
	background-color: color(gray-0);
	color: color(danger-500);
	border-color: color(danger-500);
}

.btn-tertiary {
	@include btn;
	color: color(blue-900);
	background-color: color(accent-400);
	border-color: color(accent-400);

	&:hover {
		background-color: color(accent-200);
		border-color: color(accent-200);
	}
	&:focus {
		background-color: color(secondary-600);
		border-color: 0;
		outline: 2px solid color(blue-outline);
		outline-offset: 4px;
	}
}

.btn-tertiary.disabled {
	background-color: color(gray-300);
	border-color: color(gray-300);
	color: color(gray-400);
	pointer-events: none;
}

.btn-transparent {
	// @include btn;
	background-color: transparent;
	border: 0;
	color: inherit;
	font-size: inherit;
	font: inherit;
	width: 100%;
	cursor: pointer;
	&:focus-visible {
		text-decoration: none;
		outline: auto;
		outline-offset: 2px;
		z-index: 10;
	}
}

///----- Different sizes button styles ----///
.large {
	@include text-base-350;
	padding: 12px 24px;
}
.compact {
	@include text-sm-350;
	padding: 7px 8px;
}

.rt-icon {
	flex: 1;
	height: 20px;
	width: 20px;
	margin-left: 10px;
}

.lt-icon {
	flex: 1;
	height: 20px;
	width: 20px;
	margin-right: 10px;
}
