// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns: 12 !default;
$grid-gutter-width: 24px !default;
$grid-row-columns: 6 !default;
// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-gutter-widths: (
	xs: 12px,
	// eXtra Small
	sm: 12px,
	// SMall
	md: 12px,
	// MeDium
	lg: 12px,
	// LarGe
) !default;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
	xs: 0,
	// eXtra Small
	sm: 500px,
	// SMall
	md: 720px,
	// MeDium
	lg: 1024px,
	// LarGe
) !default;

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
	sm: 1024px,
	md: 1024px,
	lg: 1024px
) !default;

$sidebar-width: 180px;
$header-height: 25px; // height of the header

$spacer: (
	'0',
	'1',
	'2',
	'4',
	'8',
	'12',
	'16',
	'24',
	'32',
	'40',
	'48',
	'56',
	'60',
	'64',
	'96',
	'128'
);
