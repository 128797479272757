@import '../../../styles/shared';

.card {
	width: 560px;
	@include flexbox;
	@include align-items(center);
	@include flex-direction(column);
	padding: 24px 60px;
	@media screen and (max-width: 425px) {
		padding: 24px 16px;
	}
}
.form {
	margin-top: 42px;
	@include flexbox;
	@include align-items(center);
	@include flex-direction(column);
	width: 100%;
}

.title {
	color: color(accent-400);
}

.info {
	@include text-base-325;
	color: color(gray-0);
}

@media screen and (max-width: 425px) {
	.info {
		@include text-sm-325;
		overflow-wrap: break-word;
	}
	p.info {
		word-break: break-word;
	}
}
