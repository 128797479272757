@import '../../styles/shared';

.header-text {
	@include text-h3-325;
	color: color(gray-0);
}
.header-cancel {
	color: color(accent-400);
	cursor: pointer;
	text-decoration: none;
}
.image-postition {
	margin: auto;
}
.account-link {
	background-color: color(gray-0);
	border-radius: 4px;
	button {
		background-color: color(accent-400);
		border-color: color(accent-400);
		color: color(blue-900);
	}
	button:hover {
		background-color: color(accent-400);
		border-color: color(accent-400);
	}
}
