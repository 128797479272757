@import '../../../styles/shared';

.image-position {
	margin: auto;
	margin-bottom: 32px;
}
.account-link {
	background-color: color(gray-0);
	border-radius: 4px;
	cursor: pointer;
}
.container {
	max-width: 540px !important;
	//background-color: red;
}
