@import '../../../styles/shared';

.container {
	@include flexbox;
	@include justify-content(flex-start);
	width: 100%;
	@include flex-direction(column);
	margin-left: 12px;
}
.error-list {
	@include text-sm-325;
	@include flexbox;
	gap: 4px;
	@include align-items(center);
	gap: 10px;
	padding: 6px;
	color: color(gray-0);
	padding-left: 20%;
}

.tick-icon {
	background-color: color(success-500);
	height: 24px;
	width: 24px;
	border-radius: 12px;
	@include flexbox;
	@include justify-content(center);
	@include align-items(center);
}
