@import '../../../styles/shared';

.group-name {
	@include text-base-700;
	padding: 8px 16px;
	background-color: color(gray-0);
	border-bottom: 1px solid color(font-body-disabled);
}

.transaction-section {
	background-color: color(gray-0);
}

.transaction-section:nth-child(n + 2) {
	border-top: 1px solid color(font-body-disabled);
}
.transaction-section:last-child {
	border-bottom-left-radius: 8px;
	border-bottom-right-radius: 8px;
	overflow: hidden;
}

.transaction-section:first-child {
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
	overflow: hidden;
}

.transaction-item {
	list-style: none;
}

.green-text {
	color: color(success-500);
}
.blue-text {
	color: color(blue-900);
}
.red-text {
	color: color(danger-500) !important;
	& path {
		fill: color(danger-500) !important;
	}
}
.yellow-pill {
	@include text-xs-325;
	display: inline-block;
	padding: 4px 12px;
	background-color: color(accent-400);
	border: 0;
	border-radius: 24px;
	color: color(gray-900);

	@media (max-width: 425px) {
		@include text-sm-350;
		overflow-wrap: break-word;
		hyphens: auto;
	}
}
.green-pill {
	@include text-xs-325;
	display: inline-block;
	padding: 4px 12px;
	background-color: color(success-500);
	border: 0;
	border-radius: 24px;
	color: color(gray-900);

	@media (max-width: 425px) {
		@include text-sm-350;
		overflow-wrap: break-word;
		hyphens: auto;
	}
}
