@import '../../../styles/shared';

.w-100 {
	width: 100%;
}
.w-90 {
	width: 90%;
}
.orange-text {
	color: color(accent-400);
}
.center-content {
	@include flexbox;
	@include align-items(center);
	@include flex-direction(column);
}
.text-center {
	text-align: center;
}
.address {
	color: color(font-helper);
	margin-top: 15px;
	width: 100%;
	padding-left: 10px;
	text-align: left;
	line-height: 1.5rem;
}
.banner {
	margin-bottom: 40px;
	width: 100%;
}

.text-sm-325 {
	@include text-sm-325;
	color: color(font-helper);
}

.button-group-col {
	@include flexbox;
	@include flex-direction(column);
	flex-wrap: wrap;
	gap: 1rem;
}
