@import '../../styles/shared';

.content-center {
	@include flexbox;
	@include justify-content(center);
}

.form {
	@include flexbox;
	@include justify-content(center);
	@include flex-direction(column);
	margin-top: 50px;
}
.w-80 {
	width: 80%;
}
