@import '../../../styles/shared';

@mixin banner {
	@include flexbox;
	@include text-sm-325;
	border: 3px solid;
	padding: 16px;
	box-shadow: 2px 2px 4px rgba(37, 40, 43, 0.1);
	text-decoration-line: none;
	border-radius: 8px;
}

@mixin fill-banner {
	@include flexbox;
	@include text-sm-325;
	padding: 16px;
	border-radius: 12px;
}

// info banner related styles
.banner-info {
	@include banner;
	border-color: color(blue-900);
}

.fill-banner-info {
	@include fill-banner;
	background-color: color(blue-900);
	color: color(gray-0);
}

// negative banner related styles
.banner-negative {
	@include banner;
	border-color: color(danger-500);
	background-color: #fef8f8;
}

.fill-banner-negative {
	@include fill-banner;
	background-color: color(danger-500);
}

// positive banner related styles
.banner-positive {
	@include banner;
	border-color: color(success-500);
}
.fill-banner-positive {
	@include fill-banner;
	background-color: color(success-500);
}

// warning banner related styles
.banner-warning {
	@include banner;
	border-color: color(accent-400);
}
.fill-banner-warning {
	@include fill-banner;
	background-color: color(accent-400);
}
.icon {
	@include flexbox;
	@include justify-content(center);
	padding: 4px 16px;
}

.title {
	@include text-h4-325;
}

.fill-title {
	@include text-base-700;
}
