@import '../../styles/shared';

.text-white {
	color: color(gray-0);
}

a.zenda-benefit-card {
	padding: 2rem;
}

a.zenda-blue-card {
	color: white;
	background-color: color(blue-900);
}

.disabled-card {
	color: color(gray-300);
	cursor: not-allowed;
}
