@import '_shared.scss';
@import 'fonts.scss';
@import 'typo.scss';
@import 'spacing.scss';
@import 'grid.scss';
@import 'flex-box.scss';
@import 'pagination.scss';
@import 'datepicker.scss';

:root {
	--color-accent: #eeeff0;
	--color-bg-cta-hover: #619edb;
	--color-bg-cta-disabled: #b0ceed;
	--color-font-body: #25282b;
	--color-font-body-disabled: #babfc4;
	--color-font-helper: #4e5154;
	--color-bg-default: #eff3fa;
	--color-bg-fill: #25282b;
	--color-primary-50: #e4e7ee;
	--color-primary-100: #bfc9db;
	--color-primary-500: #20437d;
	--color-primary-800: #072b5e;

	--color-gray-50: #f9f9fb;
	--color-gray-0: #ffffff;
	--color-gray-900: #111827;
	--color-gray-800: #6b7280;
	--color-gray-400: #9ca3af;
	--color-gray-300: #d1d5db;
	--color-gray-600: #4b5563;
	--color-gray-1000: #000000;
	--color-gray-100: #f3f4f6;
	--color-blue-1000: #001332;
	--color-blue-900: #001b47;
	--color-blue-800: #13376b;
	--color-blue-600: #244983;
	--color-blue-700: #1a4078;
	--color-blue-500: #2a518c;
	--color-blue-300: #6d83ab;
	--color-blue-50: #e6e9f0;
	--color-secondary-600: #fbb500;
	--color-text-accent: #fac300;
	--color-accent-400: #facc15;
	--color-accent-200: #fef08a;
	--color-accent-100: #fef9c3;
	--color-danger-500: #cc3333;
	--color-danger-100: #f8f8f8;
	--color-success-500: #22c55e;
	--color-bg-error-light: #f8dfe1;

	--color-blue-outline: #008fef;
}
html {
	font-size: 16px !important;
	width: 100%;
	height: 100%;
	margin: 0;
	scroll-behavior: smooth;
}

body {
	height: 100%;
	width: 100%;
	font-family: 'Gotham', 'Open Sans', sans-serif;
	// @include webkit-scroller;
	overflow: auto;
	line-height: 2rem;
	margin: 0px;
	// background-color: color(blue-900);
}

#root {
	height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
	padding: 0px;
	margin: 0px;
}

/* HTML ELEMENTS CUSTOM CSS */
* {
	box-sizing: border-box;

	&:after,
	&:before {
		box-sizing: border-box;
	}
}

a,
a:active,
a:focus,
a:hover,
button,
button:active,
button:focus,
button:hover {
	outline: none;
}

input:focus {
	border-width: 0.5px;
	border-color: red;
}

//----- React input verification code style overrides-----//
.ReactInputVerificationCode__container {
	width: 100% !important;
}
.ReactInputVerificationCode__item {
	font-weight: 400 !important;
	font-size: 24px !important;
	line-height: 32px !important;
	box-shadow: inset 0 0 0 0.5px #4e5154 !important;
	height: 48px !important;
	width: 48px !important;
	@include flexbox();
	@include align-items(center);
	@include justify-content(center);
	border-radius: 8px !important;
	background-color: color(gray-0);
}
.ReactInputVerificationCode__item.is-active {
	//box-shadow: inset 0 0 0 1px #25282b !important;
	border: 4px solid color(accent-400) !important;
	outline: none !important;
}
.ReactInputVerificationCode__item.is-filled {
	box-shadow: inset 0 0 0 1px #25282b !important;
}

.custom-nav-link {
	text-decoration: none;
	// color: color(font-helper);
	color: color(gray-0);
	@include text-base-325;
	padding: 0px 16px;
	position: relative;
	display: flex;
	gap: 8px;
	&.active {
		@include text-base-700;
		color: color(accent-400);
		path {
			fill: color(accent-400);
		}
	}
	&:hover {
		// @include text-base-700;
		text-shadow: 0px 0px 1px color(font-helper);
	}
	&.active::before {
		content: '';
		width: 100%;
		height: 5px;
		position: absolute;
		bottom: -17px;
		left: 0px;
		background: color(accent-400);
	}
	path {
		fill: color(gray-0);
	}
}

// Modal override styles
.ReactModal__Overlay {
	z-index: 9999;
	background-color: rgba(37, 40, 43, 0.85) !important;
}
.ReactModal__Content {
	max-height: calc(100vh - 2rem);
}
.modal-close-btn-container {
	position: absolute;
	right: 24px;
	top: 24px;
	cursor: pointer;
	& svg path:nth-child(1) {
		fill: color(accent-400);
	}
}
.modal-close-btn-container-dark {
	position: absolute;
	right: 24px;
	top: 24px;
	cursor: pointer;
	& svg path:nth-child(1) {
		fill: color(gray-900);
	}
}

.w-50 {
	width: 50%;
}
.w-100 {
	width: 100%;
}
.gap-20 {
	gap: 20px;
}
.gap-16 {
	gap: 16px;
}

.gap-12 {
	gap: 12px;
}

.text-center {
	text-align: center;
}

.sm-hide {
	@include media-breakpoint-down('xs') {
		display: none;
	}
}
.md-hide {
	@include media-breakpoint-down('md') {
		display: none;
	}
}

.slick-slider {
	// background-color: color(blue-900);
	background-color: transparent;
}

.slick-next:before {
	color: color(accent-400) !important;
	background-color: color(blue-900) !important;
	position: absolute;
	top: 0px;
	left: -40px;
	font-size: 32px !important;
	opacity: 1 !important;
	border-radius: 50%;
	width: 25px;
	height: 25px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.slick-prev {
	z-index: 1;
}

.slick-disabled {
	display: none !important;
}

.slick-prev:before {
	color: color(accent-400) !important;
	background-color: color(blue-900) !important;
	position: absolute;
	top: 0px;
	right: -40px;
	font-size: 32px !important;
	opacity: 1 !important;
	border-radius: 50%;
	width: 25px;
	height: 25px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.slick-custom-next {
	position: absolute;
	right: 0;
	display: flex !important;
	align-items: center !important;
	height: 48px !important;
	width: 48px !important;
	svg {
		background-color: color(blue-900);
		border: 1px solid color(accent-400);
		border-radius: 50%;
		padding: 8px;
		position: absolute;
	}
}

.slick-custom-prev {
	z-index: 1;
	position: absolute;
	display: flex !important;
	align-items: center !important;
	height: 48px !important;
	width: 48px !important;
	svg {
		left: 0;
		background-color: color(blue-900);
		border: 1px solid color(accent-400);
		border-radius: 50%;
		padding: 8px;
		position: absolute;
		transform: rotate(180deg) !important;
	}
}

.text-primary {
	color: color(gray-900);
}
.text-white {
	color: color(gray-0);
}
.text-yellow {
	color: color(accent-400);
}
.text-blue {
	color: color(blue-900);
}

.text-secondary {
	color: color(gray-600);
}

.bg-dark {
	background-color: color(blue-900);
}

.modal-header {
	padding: 20px;
	background-color: color(blue-900);
}

.modal-body {
	padding: 20px;
}

.info-card {
	@include flexbox;
	@include justify-content(space-between);
	@include align-items(center);
	background-color: color(gray-0);
	border-bottom: 1px solid color(gray-300);
	padding: 16px;
}

.info-card:last-child {
	border-bottom: none;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
}

.info-card:first-child {
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
}

input[type='radio'] {
	accent-color: color(blue-500);
}

// Class to hide the live-regions from the UI view
.visually-hidden {
	position: absolute;
	top: 0;
	left: -2px;
	overflow: hidden;
	width: 1px;
	height: 1px;
}

// Common classes being used for similar types of buttons
button.link-btn {
	text-decoration: underline;
	width: auto;
	color: color(blue-500);
	cursor: pointer;
	&:hover {
		color: color(blue-700);
	}
}
button.w-auto {
	width: auto;
}

fieldset {
	border: 0;
	padding: 0;
	margin: 0;
}
.ot-sdk-show-settings {
	display: none;
}

// Styles for the react-slick - slider
.slider-wrapper {
	display: flex !important;

	div.slick-list {
		flex: 1;
		div.slick-track {
			margin: 0;
			@media (min-width: 600px) and (max-width: 899px) {
				margin: 0 48px;
			}
			div.slick-slide {
				width: auto !important;
			}
		}
	}
}

.react-sonner-custom {
	background-color: color(accent-400);
	color: color(gray-900);
	gap: 10px;
	display: flex;
	border-radius: 0px;
	@media (max-width: 540px) {
		width: 100vw !important;
		left: 0px !important;
		position: fixed;
	}

	@media (min-width: 600px) {
		width: 507px !important;
		left: 0px !important;
		position: fixed;
	}
}

:where([data-sonner-toaster][data-x-position='center']) {
	transform: translate(-70%) !important;
	@media (max-width: 600px) {
		transform: none !important;
	}
}
