@import '../../../styles/shared';

@mixin base {
	@include flexbox;
	@include align-items(center);
	cursor: pointer;
	color: color(blue-900);
	text-decoration-thickness: 1px;
}
.link-medium {
	@include base;
	@include text-base-350;
}

.link-small {
	@include base;
	@include text-sm-350;
}
.link-large {
	@include base;
	@include text-base-350;
}
.link-basic {
	display: inline-block;
	padding: 0px 4px;
	&:focus-visible {
		outline: 2px solid color(blue-outline);
		border-radius: 123px;
	}
}
.disabled {
	cursor: not-allowed;
	pointer-events: none;
	opacity: 0.4;
}
.hide-underline {
	text-decoration: none;
}
.show-underline {
	text-decoration: underline;
}
.link-primary {
	color: color(blue-500);
	&:hover {
		color: color(blue-700);
	}
}
.link-primary.disabled {
	color: color(gray-400);
}

.link-secondary {
	color: color(gray-0);
}

.link-tertiary {
	color: color(accent-400);

	&:hover {
		color: color(accent-200);
	}
	&:focus {
		color: color(accent-100);
	}
}
.link-tertiary.disabled {
	cursor: not-allowed;
	pointer-events: none;
	color: color(blue-500);
}
