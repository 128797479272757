@import '../../styles/shared';

.dashboard-greet-text {
	@include text-h3-325;
	padding: 16px 0px 40px 0px;
	color: color(gray-0);
}
.activate-card {
	@include flexbox;
	@include align-items(flex-start);
	background-color: color(gray-0);
	padding: 16px;
	margin: 0px 8px;
	border-radius: 8px;
	gap: 16px;
	border: 2px solid color(accent-400);
	margin-bottom: 40px;
	min-height: 180px;
}

.activity-container {
	@include flexbox;
	@include justify-content(center);
	@include align-items(center);
	border: 1px solid color(font-body-disabled);
	border-radius: 18px;
	min-height: 40vh;
	margin-bottom: 30px;
}
.no-activity-text {
	@include text-h4-325;
	color: color(font-body-disabled);
	@include justify-content('flex-start');
}
.card-text-content {
	min-height: 140px;
	@include flexbox;
	@include justify-content(space-between);
	@include flex-direction(column);
}
.cash-back-card {
	border-radius: 8px;
	border-style: solid;
	border-width: 2px;
	border-color: #fac300;
}
.cash-back-card-details {
	width: 100%;
	position: relative;
	background-image: url('../../assets/img/Cashback-Light.svg');
	background-repeat: no-repeat;
	background-position: right;
	background-size: 160px 160px;
}
.cash-back-card-details::before {
	content: '';
	width: 160px;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(239, 243, 255, 0.4);
}
.cursor-pointer {
	cursor: pointer;
}
.z-10 {
	position: relative;
	// z-index: 10;
}
