@import '../../styles/shared';

.container {
	width: 100%;
	height: 100%;
	min-height: 500px;
	//padding: 136px 64px 24px 64px;
	// background-color: #ffbe69;
	// @include media-breakpoint-down('sm') {
	// 	padding: 136px 12px 0px 12px;
	// }
	// > div {
	// 	max-width: 1024px;
	// 	margin-left: auto;
	// 	margin-right: auto;
	// }
	// padding: 136px 64px 24px 64px;
	background-color: color(bg-default);
	// @include media-breakpoint-down('sm') {
	// 	padding: 136px 12px 0px 12px;
	// }
	// @include media-breakpoint-up('lg') {
	// 	> div {
	// 		max-width: 1024px;
	// 		margin-left: auto;
	// 		margin-right: auto;
	// 	}
	// }
	> div {
		padding: 16px;
		max-width: 1024px;
		margin-left: auto;
		margin-right: auto;
		top: -190px;
		position: relative;
	}
}
.sm-container {
	> div {
		top: -160px;
	}
}
.lg-container {
	> div {
		top: -240px;
	}
}
.bg-container {
	//margin-top: 112px;
	@include flexbox;
	@include justify-content(center);
	height: 195px;
	width: 100%;
	background-color: color(blue-900);
}
.bg-sm-container {
	height: 120px;
}
.bg-lg-container {
	height: 240px;
}
.image-container {
	@include flexbox;
	@include justify-content(right);
	max-width: 1024px;
	width: 100%;
	padding-right: 50px;
	position: relative;
	opacity: 0.5;
}
.image {
	position: absolute;
	top: -40px;
	height: 220px;
}
.background-dark {
	background-color: color(blue-900);
	> div {
		background-color: color(blue-900);
	}
}
.section-100 {
	min-height: 100vh;
}
.visually-hidden {
	position: absolute;
	top: 0;
	left: -2px;
	overflow: hidden;
	width: 1px;
	height: 1px;
}
