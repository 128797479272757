@import '../../../styles/shared';

$main-margin: 10px;

@mixin media-max-width() {
	@media (max-width: 550px) {
		@content;
	}
}
.card {
	@include flexbox;
	@include align-items(center);
	@include flex-direction(column);
	margin-bottom: 60px;
	border-radius: 8px;

	input {
		@media (max-width: 425px) {
			@include text-sm-325;
		}
	}
}

.form {
	margin: 0px $main-margin * 4;
	width: 400px;
	@include media-max-width() {
		width: 95%;
	}
}

.info {
	@include text-base-325;
	text-align: center;
	color: color(gray-0);
}

.title {
	color: color(accent-400);
}

.error-list {
	@include text-sm-325;
	@include flexbox;
	gap: 10px;
	@include align-items(center);
	padding: 6px;
	color: color(gray-0);
	padding-left: 20%;
}

.header {
	@include flexbox;
	@include justify-content(center);
	@include align-items(center);
	@include flex-direction(column);
	gap: 48px;
	background-color: color(blue-900);
	padding: 40px 12px 12px 12px;
	@include text-h2-325;
	color: color(accent-400);
}

.lost-text {
	color: color(gray-0);
	padding-right: 16px;
}
