@import '../../../styles/shared';

.label {
	color: color(blue-900);
	@include text-sm-325;
	@include flexbox;
	@include align-items(center);
	cursor: pointer;
	position: relative;
	width: fit-content;
	&.disabled {
		cursor: not-allowed;
		opacity: 0.7;
	}
}

.medium-radio {
	height: 16px;
	width: 16px;
}

.large-radio {
	height: 28px;
	width: 28px;
}
