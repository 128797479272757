@import '../../styles/shared';

.header-text {
	@include text-h4-325;
	color: color(accent-400);
	@include media-breakpoint-down('xs') {
		margin-left: -30px;
	}
}
.expense-header {
	@include media-breakpoint-down('xs') {
		// justify-content: center;
		@include justify-content(center);
	}
}
.header-cancel {
	color: color(bg-default);
	cursor: pointer;
	text-decoration: none;
}

.h4-bold {
	font-size: 18px;
	font-weight: 700;
	line-height: 22px;
	letter-spacing: 0.3499999940395355px;
}
.input-file {
	display: none;
}
.text-white {
	color: color(bg-default);
}

.danger-button {
	button,
	button:hover {
		border-color: color(danger-500);
		background-color: color(danger-500);
		color: color(bg-default);
	}
}
.gray-button {
	button,
	button:hover {
		border-color: color(font-body-disabled);
		background-color: color(font-body-disabled);
		color: color(blue-900);
	}
}
.success-button {
	button,
	button:hover {
		border-color: color(success-500);
		background-color: color(success-500);
		color: color(blue-900);
	}
}
.warning-button {
	button,
	button:hover {
		border-color: color(accent-400);
		background-color: color(accent-400);
		color: color(bg-default);
	}
}

.order2 {
	@include media-breakpoint-down('sm') {
		order: 1;
	}
}

.close-icon {
	// display: none;
	cursor: pointer;
	rotate: 180deg;
	@include media-breakpoint-down('xs') {
		@include flexbox;
	}
}
.image-position {
	cursor: pointer;
}
.zenda-audit-card {
	border-radius: 4px;
	border: 2px solid color(accent-400);
	background: color(bg-fill);
	color: color(bg-default);
}
.zenda-audit-link {
	text-decoration: underline;
	color: color(accent-400);
	@include text-base-700;
	cursor: pointer;
}

.success-message {
	text-align: center;
	font-size: 48px;
	font-style: normal;
	font-weight: 500;
	line-height: 120%; /* 57.6px */
	letter-spacing: 0.35px;
}

.success-image {
	margin: auto;
}
.text-capitalize {
	text-transform: capitalize;
}
