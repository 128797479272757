@font-face {
	font-family: 'Gotham';
	src: url('/assets/Fonts/Gotham-Webfont/Gotham-Bold_Web.woff');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Gotham-Book';
	src: url('/assets/Fonts/Gotham-Webfont/Gotham-Book_Web.woff');
	font-weight: 325;
	font-style: normal;
}

@font-face {
	font-family: 'Gotham';
	src: url('/assets/Fonts/Gotham-Webfont/Gotham-Medium_Web.woff');
	font-weight: 350;
	font-style: normal;
}

@font-face {
	font-family: 'Gotham';
	src: url('/assets/Fonts/Gotham-Webfont/Gotham-Light_Web.woff');
	font-weight: 300;
	font-style: normal;
}

// @font-face {
//   font-family: 'proxima-nova';
//   src:url('/assets/fonts/FontsFree-Net-Proxima-Nova-Bold.otf?agusew');
//   font-weight: 700;
//   font-style: normal;
// }

// @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&family=Poppins:wght@600;700&display=swap');

// font-family: 'Open Sans', sans-serif;
// font-family: 'Poppins', sans-serif
