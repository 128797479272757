@mixin spacing($breakpoints: $grid-breakpoints) {
	.ml-auto {
		margin-left: auto;
	}
	@each $breakpoint in map-keys($breakpoints) {
		$infix: breakpoint-infix($breakpoint, $breakpoints);

		@include media-breakpoint-up($breakpoint, $breakpoints) {
			.mx#{$infix}-auto {
				margin-left: auto;
				margin-right: auto;
			}
			.my#{$infix}-auto {
				margin-top: auto;
				margin-bottom: auto;
			}
			@each $i in $spacer {
				.mt#{$infix}-#{$i} {
					margin-top: #{$i}px;
				}
				.ml#{$infix}-#{$i} {
					margin-left: #{$i}px;
				}
				.mb#{$infix}-#{$i} {
					margin-bottom: #{$i}px;
				}
				.mr#{$infix}-#{$i} {
					margin-right: #{$i}px;
				}
				.mx#{$infix}-#{$i} {
					margin-left: #{$i}px;
					margin-right: #{$i}px;
				}
				.my#{$infix}-#{$i} {
					margin-top: #{$i}px;
					margin-bottom: #{$i}px;
				}
				.m#{$infix}-#{$i} {
					margin: #{$i}px;
				}

				.pt#{$infix}-#{$i} {
					padding-top: #{$i}px;
				}
				.pl#{$infix}-#{$i} {
					padding-left: #{$i}px;
				}
				.pb#{$infix}-#{$i} {
					padding-bottom: #{$i}px;
				}
				.pr#{$infix}-#{$i} {
					padding-right: #{$i}px;
				}
				.px#{$infix}-#{$i} {
					padding-left: #{$i}px;
					padding-right: #{$i}px;
				}
				.py#{$infix}-#{$i} {
					padding-top: #{$i}px;
					padding-bottom: #{$i}px;
				}
				.p#{$infix}-#{$i} {
					padding: #{$i}px;
				}
			}
		}
	}
}
