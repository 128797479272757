@import '../../../styles/shared';

.base-input {
	position: relative;
}
input[type='password'] {
	padding-right: 48px;
}

.input {
	width: 100%;
	color: color(blue-900);
	border-color: color(gray-300);
	border-radius: 8px;
	border-width: 0.5px;
	background-color: color(gray-0);
	border-style: solid;
	@include text-base-325;
	outline: none;
	&:focus {
		border-width: 1px;
		border-color: color(gray-300);
		outline: 3px solid color(blue-outline);
		outline-offset: 1px;
	}
	&.is-in-completed {
		border-width: 2px;
		border-color: color(danger-500);
		background-color: color(gray-0);
		color: color(danger-500);
	}
	&::placeholder {
		/* Chrome, Firefox, Opera, Safari 10.1+ */
		@include text-base-325;
		color: color(font-body-disabled);
		opacity: 1; /* Firefox */
	}

	&:-ms-input-placeholder {
		/* Internet Explorer 10-11 */
		@include text-base-325;
		color: color(font-body-disabled);
	}

	&::-ms-input-placeholder {
		/* Microsoft Edge */
		@include text-base-325;
		color: color(font-body-disabled);
	}
}

.disabled {
	cursor: not-allowed;
	pointer-events: none;
	border-color: color(font-body-disabled) !important;
	color: color(font-body-disabled) !important;
}

.medium {
	padding: 12px 48px 12px 16px;
}

.large {
}

.small {
}

.error {
}

.positive {
}
.negative {
}

@mixin btn-icon-container {
	position: absolute;
	top: 12px;
	right: 16px;
	height: 20px;
	cursor: pointer;
	width: auto !important;
}
.left-icon-container {
	position: absolute;
	top: 7px;
	left: 16px;
	cursor: pointer;
}
.input-left-padding {
	padding-left: 26px;
}
.clear-btn-container {
	@include btn-icon-container;
	cursor: pointer;
}
.btn-icon-container {
	@include btn-icon-container;
}
.btn-icon-container.no-cursor {
	pointer-events: none;
	cursor: auto;
}
.btn-icon-container.left-aligned {
	right: 48px;
}
.btn-icon-container.on-complete-clear-left-aligned {
	right: 88px;
}
.btn-icon-container.on-complete-eye-left-aligned {
	right: 48px;
}
