@import '../../../styles/shared';

.expense-item-wrapper {
	list-style: none;
	border-bottom: none;
	border-bottom-left-radius: 8px;
	border-bottom-right-radius: 8px;

	.detail-item {
		border-bottom: 1px solid color(font-body-disabled);
		background-color: color(gray-0);
		cursor: pointer;
	}
}
.expense-item-wrapper:last-child {
	.detail-item {
		border-bottom: 0;
		border-bottom-left-radius: 8px;
		border-bottom-right-radius: 8px;
	}
}
.group-name {
	@include text-base-700;
	padding: 8px 16px;
	background-color: color(gray-0);
	border-bottom: 1px solid color(font-body-disabled);
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
}

.green-text {
	color: color(success-500);
}
.blue-text {
	color: color(blue-900);
}
.text-yellow {
	color: color(accent-400);
}

.right-divider {
	border-right: solid 2px color(gray-300);
}

.travel-expense-wrapper {
	border-bottom: 0;
}

.yellow-pill {
	@include text-xs-325;

	display: inline-block;
	padding: 4px 12px;
	background-color: color(accent-400);
	border: 0;
	border-radius: 24px;
	color: color(gray-900);

	@media (max-width: 425px) {
		@include text-sm-350;
		overflow-wrap: break-word;
		hyphens: auto;
	}
}

.green-pill {
	@include text-xs-325;
	display: inline-block;
	padding: 4px 12px;
	background-color: color(success-500);
	border: 0;
	border-radius: 24px;
	color: color(gray-900);

	@media (max-width: 425px) {
		@include text-sm-350;
		overflow-wrap: break-word;
		hyphens: auto;
	}
}
