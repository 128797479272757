@import '../../styles/shared';

$main-margin: 10px;

@mixin media-max-width() {
	@media (max-width: 550px) {
		@content;
	}
}

.content {
	max-width: 550px;
	@include flexbox;
	@include justify-content(center);
	@include align-items(center);
	@include flex-direction(column);
	height: 100vh;
	overflow-y: auto;
}

.page {
	background-color: color(blue-900);
	@include flexbox;
	@include justify-content(center);
	@include align-items(center);
}

.gap-8 {
	gap: 8px;
}
