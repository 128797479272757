@import '../../../styles/shared';

.underline-none {
	text-decoration: none;
}
.radio-button {
	height: 16px;
	width: 16px;
	margin: auto;
}
.link-button {
	background-color: color(accent-400);
	color: color(blue-900);
	border-radius: 123px;
}
.error-text {
	color: color(danger-500);
}
.link-error-heading {
	font-weight: 500;
	font-size: 24px;
	line-height: 36px;
}

.link-error-text {
	@include text-sm-325;
	font-size: 15px;
}

.reverse-order {
	@include media-breakpoint-down('sm') {
		flex-direction: column-reverse;
	}
}
