@import '../../styles/shared';

.header-text {
	@include text-h3-325;
	color: color(gray-0);
}
.expense-header {
	@include media-breakpoint-down('xs') {
		// justify-content: center;
		@include justify-content(center);
	}
}
.header-cancel {
	color: color(bg-default);
	cursor: pointer;
	text-decoration: none;
}
.orange-text {
	color: color(accent-400);
}

.input-file {
	display: none;
}
.text-white {
	color: color(bg-default);
}

.danger-button {
	button,
	button:hover {
		border-color: color(danger-500);
		background-color: color(danger-500);
		color: color(bg-default);
	}
}
.gray-button {
	button,
	button:hover {
		border-color: color(font-body-disabled);
		background-color: color(font-body-disabled);
		color: color(blue-900);
	}
}
.success-button {
	button,
	button:hover {
		border-color: color(success-500);
		background-color: color(success-500);
		color: color(blue-900);
	}
}
.warning-button {
	button,
	button:hover {
		border-color: color(accent-400);
		background-color: color(accent-400);
		color: color(blue-900);
	}
}

.order2 {
	@include media-breakpoint-down('sm') {
		order: 1;
	}
}

.close-icon {
	// display: none;
	cursor: pointer;
	rotate: 180deg;
	width: auto;
	@include media-breakpoint-down('xs') {
		@include flexbox;
	}
}
.cursor-pointer {
	cursor: pointer;
}
.cursor-not-allowed {
	cursor: not-allowed;
}
.zenda-audit-card {
	border-radius: 4px;
	border: 2px solid color(accent-400);
	background: color(blue-900);
	color: color(bg-default);
}
.zenda-audit-link {
	text-decoration: underline;
	width: auto;
	color: color(accent-400);
	@include text-sm-325;
	cursor: pointer;
}

.success-image {
	margin: auto;
}
.text-capitalize {
	text-transform: capitalize;
}

.notes-section {
	width: 70%;
	@include flexbox;
	@include justify-content(flex-end);
}
.status-button {
	@include text-sm-700;
	text-decoration: none;
}
.learn-more-btn {
	margin-top: 12px;
}
.learnmore-modal-btn {
	width: auto;
}
.cash-back-card {
	border-radius: 8px;
	border-style: solid;
	border-width: 2px;
	border-color: #fac300;
}
.cash-back-card-details {
	@include flexbox;
	@include justify-content(space-between);
	position: relative;
	background-image: url('../../assets/img/Cashback-Light.svg');
	background-repeat: no-repeat;
	background-size: 120px 120px;
	width: 100%;
	background-position: 75% 15%;
}
.cash-back-card-details::before {
	content: '';
	width: 120px;
	position: absolute;
	top: 0;
	right: 20%;
	bottom: 0;
	background-color: rgba(239, 243, 255, 0.4);
}
.z-10 {
	position: relative;
	z-index: 10;
}
