@import '../../styles/shared';

.footer {
	@include flexbox;
	@include justify-content(center);
	background-color: color(blue-900);
	color: color(gray-0);
	box-shadow: 2px 2px 4px rgba(37, 40, 43, 0.1);
}

.container {
	max-width: 1024px;
	padding: 48px 24px;
}

.copyright {
	@include text-base-350;
	@include flexbox;
	@include align-items(center);
	flex: 1;
	text-align: left;
	padding: 10px 0px;
	word-spacing: 3px;
}
.text-content {
	@include text-sm-325;
	color: color(font-body-disabled);
}
.align-center {
	@include flexbox;
	@include justify-content(center);
	@include align-items(center);
}

.footer-link {
	@include flexbox;
	@include align-items(center);
	flex: 1;
	gap: 16px;
	padding: 0px 23px;
	@include media-breakpoint-down('md') {
		padding: 0px;
	}
}
.row div {
	padding: 20px 0px;
}
.footer-here-link {
	& a {
		color: color(font-body-disabled);
		&:focus-visible {
			outline: auto;
		}
	}
}

.mobile-column {
	@include media-breakpoint-down('md') {
		@include flex-direction(column);
		gap: 23px;
	}
}
