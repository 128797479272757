.btn-wrapper {
	gap: 0.5rem;
	button {
		flex: 1;
	}
}

.fix-withdrawal-wrapper {
	max-width: 550px !important;
}
