@import 'shared.scss';

.zenda-datepicker {
	color: color(blue-900);
	border-color: color(gray-300);
	border-radius: 8px;
	border-width: 0.5px;
	background-color: color(gray-0);
	border-style: solid;
	@include text-base-325;
	padding: 12px 48px 12px 16px !important;
	letter-spacing: 1px;
	outline: none;
	width: 100%;
	&:focus {
		border-width: 1px;
		border-color: color(gray-300);
		outline: 3px solid color(blue-outline);
		outline-offset: 1px;
	}
	&.is-in-completed {
		border-color: color(danger-500);
		background-color: color(gray-0);
	}
	&::placeholder {
		/* Chrome, Firefox, Opera, Safari 10.1+ */
		@include text-base-325;
		color: color(font-body-disabled);
		opacity: 1; /* Firefox */
	}

	&:-ms-input-placeholder {
		/* Internet Explorer 10-11 */
		@include text-base-325;
		color: color(font-body-disabled);
	}

	&::-ms-input-placeholder {
		/* Microsoft Edge */
		@include text-base-325;
		color: color(font-body-disabled);
	}
	&:disabled {
		pointer-events: none;
		border-color: color(font-body-disabled) !important;
		color: color(font-body-disabled) !important;
	}
}
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
	background-color: color(blue-900);
	color: color(gray-0);
}

.react-datepicker__day {
	outline: none;
}

.react-datepicker__day--keyboard-selected {
	outline: auto;
	outline-color: color(blue-outline);
	outline-offset: 2px;
	background-color: transparent;
}

.react-datepicker__day--selected {
	outline: none;
}

.react-datepicker__day--selected[tabindex='0'] {
	&:focus-visible {
		outline: auto !important;
		outline-color: color(blue-outline) !important;
		outline-offset: 2px !important;
	}
}

.react-datepicker__header {
	background-color: color(accent);
}

.react-datepicker-wrapper {
	width: 100%;
}

.react-datepicker__calendar-icon {
	cursor: pointer;
	border: 0;
	background-color: transparent;
	position: absolute;
	padding: 0 6px !important;
	top: 12px;
	right: 16px;
	height: 20px;
	width: auto;

	&:focus-visible {
		outline-offset: 2px;
	}
}

button.react-datepicker__navigation:focus {
	outline: auto;
}
