@import '../../styles/shared';

.background-blue {
	background-color: color(blue-900);
}
.close-btn {
	width: auto;
}
.update-email-btn {
	text-decoration: underline;
	color: color(blue-500);
	cursor: pointer;
	&:hover {
		color: color(blue-700);
	}
}

.contact-us-form {
	@media screen and (max-width: 375px) {
		max-width: 300px;
	}
}
