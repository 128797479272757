@import '../../../styles/shared';

.card {
	width: 560px;
	@include flexbox;
	@include align-items(center);
	@include flex-direction(column);
	// border: 1px solid color('bg-cloud');
	padding: 24px 60px;
	// background-color: color('bg-cloud');
	// border-radius: 8px;
}
.form {
	@include flexbox;
	@include align-items(center);
	@include flex-direction(column);
	width: 100%;
}

.title {
	color: color(accent-400);
}
.lost-text {
	color: color(gray-0);
}

.text-white {
	color: color(gray-0);
}
