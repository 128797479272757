@import '../../styles/shared';

$main-margin: 10px;

@mixin media-max-width() {
	@media (max-width: 550px) {
		@content;
	}
}

.content {
	@include flexbox;
	@include justify-content(center);
	height: 100vh;
	overflow-y: auto;
	background-color: color(blue-900);
}
.login-card {
	@include flexbox;
	@include align-items(center);
	@include flex-direction(column);
	padding: 16px;
}

.form-width {
	@include flexbox;
	@include flex-direction(column);
	//margin: $main-margin * 4;
	width: 433px;
	@include media-max-width() {
		width: 95%;
	}
}

.login-title {
	@include text-h2-325;
	color: color(blue-900);
}

// .forgotLink {
// 	@include text-sm-325;
// 	color: color(blue-900);
// }
.button-card {
	@include flexbox;
	@include flex-direction(column);
	@include justify-content(center);
	@include align-items(center);
}

.button {
	@include flexbox;
	@include justify-content(center);
	@include align-items(center);
	width: 250px;
	margin-top: 16px;
	button {
		width: 172px;
	}
}
.banner {
	width: 368px;
}

.message-text {
	font-weight: normal;
}

.header {
	@include flexbox;
	@include justify-content(center);
	@include align-items(center);
	@include flex-direction(column);
	gap: 10px;
	// background-color: color(blue-900);
	background-color: color(blue-900);
	padding: 80px 12px;
}

.header-bottom-padding {
	padding-bottom: 0px;
}

.text-yellow {
	color: color(accent-400);
}
.text-white {
	color: color(gray-0);
}

.margin-top-none {
	margin-top: 0px;
}
