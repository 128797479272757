@import '../../../styles/shared';

.date-picker-header {
	display: flex;
	flex-direction: column;

	.date-picker-month-content {
		display: flex;
		justify-content: space-between;
		align-items: center;
		.date-picker-month {
			flex: 1;
			@include text-sm-325;
			font-weight: bold;
			outline: none;
			&:focus-visible {
				text-decoration: underline;
			}
		}

		.date-picker-button {
			cursor: pointer;
			border: 0;
			&:first-child {
				transform: rotate(180deg);
			}

			&:focus-visible {
				outline: auto;
			}
		}
	}

	.date-picker-selects {
		display: flex;
		justify-content: space-evenly;
	}
}
