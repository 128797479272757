@import '../../../styles/shared';

.form-control-container {
	margin-bottom: 15px;
	width: 100%;
	// padding-left: 5px;
}

.error-control-container input {
	border-width: 2px;
	border-color: color(danger-500);
	background-color: color(gray-0);
	color: color(danger-500);
}

.form-control-caption-dark {
	margin: 2px 0px 0px 0px;
	display: block;
	@include text-sm-325;
	// color: color(font-helper);
	color: color(gray-0);
}
.form-control-caption-white {
	margin: 2px 0px 0px 0px;
	display: block;
	@include text-sm-325;
	// color: color(font-helper);
	color: color(gray-900);
}

.form-control-error-danger {
	margin: 2px 0px 0px 0px;
	display: block;
	color: color(danger-500);
	@include text-sm-325;
}
.form-control-error-gray {
	margin: 2px 0px 0px 0px;
	display: block;
	color: color(gray-0);
	@include text-sm-325;
}

.description {
	margin: 2px 0px 0px 0px;
}
