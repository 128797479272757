@import '../../../styles/shared';

@mixin badge {
	@include flexbox;
	@include text-sm-700;
	@include align-items(center);
	@include justify-content(center);
	border-radius: 16px;
	padding: 4px 12px;
	text-decoration-line: none;
	width: fit-content;
}

// info badge related styles
.badge-info {
	@include badge;
	background-color: color(accent-400);
}

// negative badge related styles
.badge-negative {
	@include badge;
	background-color: color(danger-500);
}

// positive badge related styles
.badge-positive {
	@include badge;
	background-color: color(success-500);
}

// warning badge related styles
.badge-warning {
	@include badge;
	background-color: color(accent-400);
}

.badge-secondary {
	@include badge;
	border: 1px solid color(gray-900);
}
.icon {
	@include flexbox;
	@include align-items(center);
	@include justify-content(center);
	padding: 16px;
}
.badge-content {
	@include flexbox;
	@include justify-content(center);
	@include align-items(center);
	gap: 9px;
}
