@import '../../../styles/shared';

.icon-link-wrapper {
	display: block;
	text-decoration: none;
	background-color: color(gray-0);
	color: color(gray-900);
	width: 100%;
	padding: 1rem;
	border-bottom: 1px solid color(gray-300);

	.btn-content {
		display: flex;
		gap: 12px;

		.btn-content-center {
			display: flex;
			flex: 1;
			align-items: center;
			gap: 12px;
			min-height: 40px;

			.center-left {
				flex: 1;
			}
			.center-right {
				text-align: end;
			}
		}
	}

	&:focus-visible {
		outline: auto;
		outline-offset: 2px;
		z-index: 10;
		outline-color: color(blue-900);
	}
}

.icon-btn-wrapper {
	cursor: pointer;

	.btn-content {
		text-align: start;
	}
}

// .icon-btn-wrapper:hover {
// 	background-color: color(gray-100);
// }

.is-first-button {
	border-bottom: 1px solid color(gray-300);
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
}

.is-last-button {
	border-bottom: 0;
	border-bottom-left-radius: 8px;
	border-bottom-right-radius: 8px;
}

.static-temp-div {
	min-width: 24px;
}
