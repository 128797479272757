@import '../../../styles/shared';

.text-orange {
	color: color(accent-400);
}

.text-center {
	text-align: center;
}
.md-reverse {
	@include media-breakpoint-down('md') {
		flex-direction: column-reverse;
	}
}
