@import '../../styles/shared';

.close-icon {
	cursor: pointer;
	rotate: 180deg;
	@include media-breakpoint-down('xs') {
		@include flexbox;
	}
}
.header-text {
	@include text-h4-325;
	color: color(accent-400);
	@include media-breakpoint-down('xs') {
		margin-left: -30px;
	}
}
.expense-header {
	@include media-breakpoint-down('xs') {
		@include justify-content(center);
	}
}

.h4-bold {
	@include text-base-700;
	font-size: 18px;
}

.sm-reverse {
	@include media-breakpoint-down('sm') {
		flex-direction: column-reverse;
	}
}

.orange-text {
	color: color(accent-400);
}
