@import '../_variables';

@mixin text-h1-325 {
	font-family: 'Gotham-Book', sans-serif;
	font-size: 48px;
	font-weight: 325;
	line-height: 56px;
	letter-spacing: -2px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@mixin text-h2-325 {
	font-family: 'Gotham-Book', sans-serif;
	font-size: 36px;
	font-weight: 325;
	line-height: 42px;
	letter-spacing: -1px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@mixin text-h3-325 {
	font-family: 'Gotham-Book', sans-serif;
	font-size: 30px;
	font-weight: 325;
	line-height: 34px;
	letter-spacing: 0px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@mixin text-h4-325 {
	font-family: 'Gotham-Book', sans-serif;
	font-size: 24px;
	font-weight: 325;
	line-height: 28px;
	letter-spacing: 0px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@mixin text-h4-700 {
	font-family: 'Gotham', sans-serif;
	font-size: 24px;
	font-weight: 700;
	line-height: 28px;
	letter-spacing: 0px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

// old-h4--24
// new-h4-24px;

@mixin text-h5-300 {
	font-family: 'Gotham', sans-serif;
	font-size: 20px;
	font-weight: 300;
	line-height: 23px;
	letter-spacing: 0px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
@mixin text-h5-325 {
	font-family: 'Gotham-Book', sans-serif;
	font-size: 20px;
	font-weight: 325;
	line-height: 23px;
	letter-spacing: 0px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
@mixin text-h5-350 {
	font-family: 'Gotham', sans-serif;
	font-size: 20px;
	font-weight: 350;
	line-height: 23px;
	letter-spacing: 0px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
@mixin text-h5-700 {
	font-family: 'Gotham', sans-serif;
	font-size: 20px;
	font-weight: 700;
	line-height: 23px;
	letter-spacing: 0px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@mixin text-h6-325 {
	font-family: 'Gotham-Book', sans-serif;
	font-size: 18px;
	font-weight: 325;
	line-height: 28px;
	letter-spacing: 0px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
@mixin text-h6-350 {
	font-family: 'Gotham', sans-serif;
	font-size: 18px;
	font-weight: 350;
	line-height: 28px;
	letter-spacing: 0px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@mixin text-h6-700 {
	font-family: 'Gotham', sans-serif;
	font-size: 18px;
	font-weight: 700;
	line-height: 28px;
	letter-spacing: 0px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@mixin text-base-325 {
	font-family: 'Gotham-Book', sans-serif;
	font-size: 16px;
	font-weight: 325;
	line-height: 18px;
	letter-spacing: 0px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@mixin text-base-350 {
	font-family: 'Gotham', sans-serif;
	font-size: 16px;
	font-weight: 350;
	line-height: 18px;
	letter-spacing: 0px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@mixin text-base-700 {
	font-family: 'Gotham', sans-serif;
	font-size: 16px;
	font-weight: 700;
	line-height: 18px;
	letter-spacing: 0px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@mixin text-sm-325 {
	font-family: 'Gotham-Book', sans-serif;
	font-size: 14px;
	font-weight: 325;
	line-height: 16px;
	letter-spacing: 0px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@mixin text-sm-350 {
	font-family: 'Gotham', sans-serif;
	font-size: 14px;
	font-weight: 350;
	line-height: 16px;
	letter-spacing: 0px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@mixin text-sm-700 {
	font-family: 'Gotham', sans-serif;
	font-size: 14px;
	font-weight: 700;
	line-height: 16px;
	letter-spacing: 0px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@mixin text-xs-325 {
	font-family: 'Gotham-Book', sans-serif;
	font-size: 12px;
	font-weight: 325;
	line-height: 14px;
	letter-spacing: 0px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@mixin text-xs-700 {
	font-family: 'Gotham', sans-serif;
	font-size: 12px;
	font-weight: 700;
	line-height: 14px;
	letter-spacing: 0px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
