@import '../../styles/shared';

.container {
	//position: fixed;
	height: 58px;
	width: 100%;
	background-color: color(blue-900);
	@include flexbox;
	@include align-items(center);
	box-shadow: 2px 2px 4px rgba(37, 40, 43, 0.1);
	z-index: 999;
}

.menu {
	@include flexbox;
	@include align-items(center);
	gap: 12px;
	margin: auto;
	width: 100%;
	@include media-breakpoint-down('lg') {
		max-width: 1024px;
		padding: 0px 16px;
		//background-color: red;
	}
	// @include media-breakpoint-down('sm') {
	// 	max-width: auto;
	// 	//background-color: green;
	// }
	//width: 80%;
}

.menu-bar {
	@include flexbox;
	@include align-items(center);
	padding: 16px;
	//width: 10%;
	width: auto;
}

.logo {
	@include flexbox;
	@include align-items(center);
	@include justify-content(flex-end);
	padding: 16px;
	//width: 10%;
	width: auto;
	// cursor: pointer;
}
.side-bar {
	background-color: color(blue-900);
	// background-color: color(blue-900);
	@include flexbox;
	// @include align-items(center);
	@include flex-direction(column);
	position: fixed;
	padding: 16px;
	z-index: 2;
	height: 100vh;
	width: 350px;
	left: -350px;
	color: color(gray-0);
	opacity: 0;
	transition: all 0.25s;
	-webkit-transition: all 0.25s;
	@include media-breakpoint-down('xs') {
		width: 100%;
		left: -500px;
	}
}

.side-bar-open {
	opacity: 1;
	left: 0;
}

.close-icon {
	@include flexbox;
	@include align-items(center);
	cursor: pointer;
	transform: rotate(180deg);
	width: fit-content;
	padding: 0;
}

.side-bar-icon {
	padding: 0;
	@include flexbox;
	cursor: pointer;
}
.sidebar-logo {
	@include flexbox;
	@include justify-content(center);
	flex: 1;
}
.side-bar-option-name {
	@include text-base-325;
}
.side-bar-option {
	@include flexbox;
	@include align-items(center);
	@include justify-content(space-between);
	padding: 16px 0px;
	border-top: 1px solid color(gray-0);
	cursor: pointer;
}
.sup-badge {
	@include flexbox;
	@include justify-content(center);
	@include align-items(center);
	position: absolute;
	top: -9px;
	left: 26px;
	border-radius: 50%;
	width: 18px;
	height: 18px;
	color: color(blue-900);
	background-color: color(accent-400);
	font-size: 12px;
	font-style: normal;
	font-weight: 600;
}
