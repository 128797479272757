@import '../../styles/shared';

.expense-header {
	flex: 1;
}

.close-icon {
	// display: none;
	cursor: pointer;
	rotate: 180deg;
	@include media-breakpoint-down('xs') {
		@include flexbox;
	}
}

.close-icon {
	// display: none;
	cursor: pointer;
	rotate: 180deg;
	@include media-breakpoint-down('xs') {
		@include flexbox;
	}
}

.header-text {
	@include text-h3-325;
	color: color(gray-0);
}

.success-image {
	margin: auto;
}

.input-file {
	display: none;
}
.image-position {
	cursor: pointer;
}

.success-message {
	@include text-h1-325;
	color: color(accent-400);
}

.error-document {
	background-color: color(bg-error-light);
}

.md-reverse {
	@include media-breakpoint-down('md') {
		flex-direction: column-reverse;
	}
}
