@import '../../../styles/shared';

.hr {
	width: 100%;
}

.expense-row {
	@include flexbox;
	@include justify-content(space-between);
	@include align-items(center);
}

.audit-info-modal {
	background-color: color(gray-0);
}

.audit-info-header {
	background-color: color(blue-900);
	border-style: solid;
	border-width: 2px;
	border-color: color(accent-400);
	border-radius: 12px 12px 0px 0px;
}
