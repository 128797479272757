@import '../../../styles/shared';

.form-control-label {
	margin: 0px 0px 10px 0px;
	display: block;
	width: 100%;
	@include text-base-325;
}

.dark-label {
	color: color(gray-900);
}
.light-label {
	color: color(gray-0);
}
