@import '../../../styles/shared';

@mixin background {
	@include flexbox;
	@include justify-content(center);
	@include align-items(center);
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 99999;
}

.loader-box {
	@include background;
}

.loader-background {
	@include background;
	background-color: color(gray-900);
	opacity: 0.5;
}

.app-loader {
	@include background;
}
