@import '../../styles/shared';

.header-text {
	@include text-h3-325;
	color: color(bg-default);
}

.tab-btn {
	width: auto;
	padding: 0;
}

.scroll-option {
	background-color: transparent;
	color: color(bg-default);
	@include flexbox;
	@include justify-content(center);
	position: relative;
	cursor: pointer;
	padding: 12px 16px;
	:first-child {
		padding: 5px 8px 5px 0;
	}
}
.selected-option {
	position: absolute;
	z-index: 10;
	border-style: solid;
	border-width: 2px;
	border-color: color(accent-400);
	bottom: 0px;
	width: 100%;
}

.option-badge {
	background-color: color(accent-400);
	border-radius: 155px;
	display: inline-flex;
	@include flex-direction(column);
	@include justify-content(center);
	@include align-items(center);
	width: 24px;
	height: 24px;
	padding: 10px;
	gap: 10px;
	color: color(bg-fill);
}

.activity-container {
	@include flexbox;
	@include justify-content(center);
	@include align-items(center);
	border: 1px solid color(font-body-disabled);
	border-radius: 18px;
	min-height: 40vh;
	margin-bottom: 30px;
}
.no-activity-text {
	@include text-h4-325;
	color: color(font-body-disabled);
	@include justify-content('flex-start');
}
.gray-1000 {
	color: color(gray-1000);
}

.para-link {
	@include flexbox;
	@include justify-content(flex-end);
	min-width: 150px;
}

.tab-header-note {
	@media (max-width: 768px) {
		flex-wrap: wrap;
		p {
			justify-content: flex-start;
		}
	}
}
