@import '../../../styles/shared';

.label {
	color: color(blue-900);
	@include text-sm-325;
	@include flexbox;
	@include align-items(flex-start);
	cursor: pointer;
	position: relative;
	width: fit-content;
	&.disabled {
		cursor: not-allowed;
		opacity: 0.7;
	}
}
.disabled {
	border-color: color(gray-300) !important;
}
.input-checkbox {
	height: 100%;
	width: 100%;
	position: absolute;
	opacity: 0;
	top: 0;
	left: 0;
	margin: 0;
	padding: 0;
	cursor: inherit;
}

.input-checkbox:focus-visible + .checkbox-span {
	outline: auto;
}

.box {
	display: inline-block;
	border-color: color(blue-900);
	width: 26px;
	height: 26px;
	margin-right: 8px;
	border-radius: 8px;
	border: 0.5px solid;
	background-color: color(gray-0);
	&.checked {
		// background-color: color(success-500);
		// border-color: color(success-500);
		background-size: 18px;
		background-repeat: no-repeat;
		background-position: center;
	}
}

.large {
	width: 26px;
	height: 26px;
}
.medium {
	width: 18px;
	height: 18px;
	border-radius: 4px;
	padding: 2px;
	background-size: auto !important;
}

.primary {
	&.checked {
		background-color: color(primary-800);
		border-color: color(primary-800);
		background-image: url('../../icons/source/complete-white.svg');
	}
}

.success {
	&.checked {
		background-color: color(success-500);
		border-color: color(success-500);
		background-image: url('../../icons/source/complete.svg');
	}
}
