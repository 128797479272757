@import '../../../styles/shared';

.radio-button {
	height: 16px;
	width: 16px;
	margin: auto;
}

.orange-text {
	color: color(accent-400);
}

textarea {
	width: 100%;
}

.reverse-order {
	@include media-breakpoint-down('sm') {
		flex-direction: column-reverse;
	}
}
