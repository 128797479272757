@import '../../styles/shared';

.title-row {
	@include flexbox;
	@include justify-content(space-between);
	margin-bottom: 12px;
	flex: 1;
}

.detail-item {
	background-color: color(gray-0);
	border-bottom: 1px solid color(font-body-disabled);
	cursor: pointer;
}
.detail-item:last-child {
	border-bottom: none;
	border-bottom-left-radius: 8px;
	border-bottom-right-radius: 8px;
}
.detail-item:nth-child(2) {
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
}

.text-white {
	color: color(gray-0);
}
.text-yellow {
	color: color(accent-400);
}
.text-gray {
	color: color(gray-600);
}
.cursor-none {
	cursor: not-allowed;
	pointer-events: none;
	& > div,
	p,
	svg > path {
		color: color(gray-300);
		fill: color(gray-300);
	}
}
.disabled-amount {
	color: color(gray-300);
}
