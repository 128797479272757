@import '../../../styles/shared';

$main-margin: 10px;

@mixin media-max-width() {
	@media (max-width: 550px) {
		@content;
	}
}

.card {
	@include flexbox;
	@include align-items(center);
	@include flex-direction(column);
	border-radius: 8px;
}
.form {
	margin: 0px $main-margin * 4;
	max-width: 480px;
	@include media-max-width() {
		width: 95%;
	}
}
.info {
	@include text-base-325;
	text-align: center;
	color: color(gray-0);
}

.title {
	color: color(accent-400);
}

.header {
	@include flexbox;
	@include justify-content(center);
	@include align-items(center);
	@include flex-direction(column);
	gap: 10px;
	background-color: color(blue-900);
	padding: 40px 12px 12px 12px;
	@include text-h2-325;
	color: color(accent-400);
}
.check-box-text {
	@include text-sm-325;
	padding: 16px 0px;
	color: color(gray-0);
}
.check-box {
	@include flexbox;
	@include flex-direction(column);
	padding-top: 10px;
	padding: 0px 40px;
	text-align: center;
}
.text-left {
	text-align: left;
	color: color(gray-0);
}

.link-position {
	margin-top: -2px;
}

.link-position {
	& a {
		position: relative;
		z-index: 3;
	}
}
.lost-text {
	color: color(gray-0);
	padding-right: 20px;
	@include text-base-325;
}
