@import '../../styles/shared';

@mixin media-max-width() {
	@media (max-width: 550px) {
		@content;
	}
}

.content {
	min-height: 100vh;
	background-color: color('bg-default');
	padding-bottom: 48px;
	background-color: color(blue-900);
}
.stepper {
	width: 80%;
	padding-top: 70px;
	padding-bottom: 70px;
}
